/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Pagination interface.
 */
export function instanceOfPagination(value) {
    if (!('page' in value) || value['page'] === undefined)
        return false;
    if (!('size' in value) || value['size'] === undefined)
        return false;
    if (!('totalPages' in value) || value['totalPages'] === undefined)
        return false;
    if (!('totalElements' in value) || value['totalElements'] === undefined)
        return false;
    return true;
}
export function PaginationFromJSON(json) {
    return PaginationFromJSONTyped(json, false);
}
export function PaginationFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'page': json['page'],
        'size': json['size'],
        'totalPages': json['totalPages'],
        'totalElements': json['totalElements'],
    };
}
export function PaginationToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'page': value['page'],
        'size': value['size'],
        'totalPages': value['totalPages'],
        'totalElements': value['totalElements'],
    };
}
