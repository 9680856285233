/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ScheduleRefFromJSON, ScheduleRefToJSON, } from './ScheduleRef';
/**
 * Check if a given object implements the Agency interface.
 */
export function instanceOfAgency(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    return true;
}
export function AgencyFromJSON(json) {
    return AgencyFromJSONTyped(json, false);
}
export function AgencyFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'fullName': json['fullName'] == null ? undefined : json['fullName'],
        'schedule': ScheduleRefFromJSON(json['schedule']),
    };
}
export function AgencyToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'fullName': value['fullName'],
        'schedule': ScheduleRefToJSON(value['schedule']),
    };
}
