/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StationFromJSON, StationToJSON, } from './Station';
import { PlannedTimeFromJSON, PlannedTimeToJSON, } from './PlannedTime';
import { AccessibilityFromJSON, AccessibilityToJSON, } from './Accessibility';
import { PlatformFromJSON, PlatformToJSON, } from './Platform';
/**
 * @export
 */
export const StopRealtimeStatusEnum = {
    Forecast: 'FORECAST',
    Past: 'PAST'
};
/**
 * Check if a given object implements the Stop interface.
 */
export function instanceOfStop(value) {
    if (!('tripId' in value) || value['tripId'] === undefined)
        return false;
    if (!('sequence' in value) || value['sequence'] === undefined)
        return false;
    if (!('station' in value) || value['station'] === undefined)
        return false;
    return true;
}
export function StopFromJSON(json) {
    return StopFromJSONTyped(json, false);
}
export function StopFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'tripId': json['tripId'],
        'sequence': json['sequence'],
        'station': StationFromJSON(json['station']),
        'arrivalPlatform': json['arrivalPlatform'] == null ? undefined : PlatformFromJSON(json['arrivalPlatform']),
        'departurePlatform': json['departurePlatform'] == null ? undefined : PlatformFromJSON(json['departurePlatform']),
        'plannedArrivalTime': json['plannedArrivalTime'] == null ? undefined : PlannedTimeFromJSON(json['plannedArrivalTime']),
        'plannedDepartureTime': json['plannedDepartureTime'] == null ? undefined : PlannedTimeFromJSON(json['plannedDepartureTime']),
        'arrivalTime': json['arrivalTime'] == null ? undefined : (new Date(json['arrivalTime'])),
        'arrivalTimeNextDay': json['arrivalTimeNextDay'] == null ? undefined : json['arrivalTimeNextDay'],
        'departureTime': json['departureTime'] == null ? undefined : (new Date(json['departureTime'])),
        'departureTimeNextDay': json['departureTimeNextDay'] == null ? undefined : json['departureTimeNextDay'],
        'arrivalTimeRt': json['arrivalTimeRt'] == null ? undefined : (new Date(json['arrivalTimeRt'])),
        'departureTimeRt': json['departureTimeRt'] == null ? undefined : (new Date(json['departureTimeRt'])),
        'canceled': json['canceled'] == null ? undefined : json['canceled'],
        'deleted': json['deleted'] == null ? undefined : json['deleted'],
        'realtimeStatus': json['realtimeStatus'] == null ? undefined : json['realtimeStatus'],
        'accessibility': json['accessibility'] == null ? undefined : AccessibilityFromJSON(json['accessibility']),
    };
}
export function StopToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'tripId': value['tripId'],
        'sequence': value['sequence'],
        'station': StationToJSON(value['station']),
        'arrivalPlatform': PlatformToJSON(value['arrivalPlatform']),
        'departurePlatform': PlatformToJSON(value['departurePlatform']),
        'plannedArrivalTime': PlannedTimeToJSON(value['plannedArrivalTime']),
        'plannedDepartureTime': PlannedTimeToJSON(value['plannedDepartureTime']),
        'arrivalTime': value['arrivalTime'] == null ? undefined : ((value['arrivalTime']).toISOString()),
        'arrivalTimeNextDay': value['arrivalTimeNextDay'],
        'departureTime': value['departureTime'] == null ? undefined : ((value['departureTime']).toISOString()),
        'departureTimeNextDay': value['departureTimeNextDay'],
        'arrivalTimeRt': value['arrivalTimeRt'] == null ? undefined : ((value['arrivalTimeRt']).toISOString()),
        'departureTimeRt': value['departureTimeRt'] == null ? undefined : ((value['departureTimeRt']).toISOString()),
        'canceled': value['canceled'],
        'deleted': value['deleted'],
        'realtimeStatus': value['realtimeStatus'],
        'accessibility': AccessibilityToJSON(value['accessibility']),
    };
}
