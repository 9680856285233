/* tslint:disable */
/* eslint-disable */
/**
 * Netcetera PIDS - Hub Cache API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.17
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { LineFromJSON, LineToJSON, } from './Line';
import { AffectedStationFromJSON, AffectedStationToJSON, } from './AffectedStation';
import { TextFromJSON, TextToJSON, } from './Text';
import { IntervalFromJSON, IntervalToJSON, } from './Interval';
/**
 * @export
 */
export const DisruptionMessageCauseEnum = {
    Emergency: 'EMERGENCY',
    Disruption: 'DISRUPTION',
    ConstructionWork: 'CONSTRUCTION_WORK',
    EndInformation: 'END_INFORMATION',
    AdvanceInformation: 'ADVANCE_INFORMATION',
    Information: 'INFORMATION'
};
/**
 * Check if a given object implements the DisruptionMessage interface.
 */
export function instanceOfDisruptionMessage(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('activeIntervals' in value) || value['activeIntervals'] === undefined)
        return false;
    if (!('schedule' in value) || value['schedule'] === undefined)
        return false;
    return true;
}
export function DisruptionMessageFromJSON(json) {
    return DisruptionMessageFromJSONTyped(json, false);
}
export function DisruptionMessageFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'activeIntervals': (json['activeIntervals'].map(IntervalFromJSON)),
        'cause': json['cause'] == null ? undefined : json['cause'],
        'texts': json['texts'] == null ? undefined : (json['texts'].map(TextFromJSON)),
        'stations': json['stations'] == null ? undefined : (json['stations'].map(AffectedStationFromJSON)),
        'lines': json['lines'] == null ? undefined : (json['lines'].map(LineFromJSON)),
        'schedule': json['schedule'],
    };
}
export function DisruptionMessageToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'activeIntervals': (value['activeIntervals'].map(IntervalToJSON)),
        'cause': value['cause'],
        'texts': value['texts'] == null ? undefined : (value['texts'].map(TextToJSON)),
        'stations': value['stations'] == null ? undefined : (value['stations'].map(AffectedStationToJSON)),
        'lines': value['lines'] == null ? undefined : (value['lines'].map(LineToJSON)),
        'schedule': value['schedule'],
    };
}
