<script lang="ts">
  import { _ } from 'svelte-i18n';
  import { format } from 'date-fns';
  import { Table, TableCol } from '@pids/shared-component';
  import { type Platform, type Stop, TripDetailsTypeEnum } from 'src/generated/service/cache-api';
  import AccessibilityIcon from 'src/components/shared/AccessibilityIcon.svelte';

  export let stops: Stop[] | undefined;
  export let highlightedStationId: string | undefined | null;
  export let tripType: TripDetailsTypeEnum;

  const formatPlatform = (platform: Platform): string => {
    if (!platform.name) {
      return '';
    }

    return `${platform.name} ${formatPlatformSectors(platform)}`;
  };

  const formatPlatformSectors = (platform: Platform) => {
    return platform.sectors?.length ? `[${platform.sectors.sort().join(',')}]` : '';
  };

  const formatTime = (date: Date): string => {
    return format(date, 'HH:mm');
  };

  const prependNextDayPrefix = (time: string, isNextDay?: boolean) => {
    if (!time) {
      return '';
    }
    return isNextDay ? `+${time}` : time;
  };

  $: isCancelledFromEnd = stops?.[stops.length - 1].canceled;

  $: firstCanceledIndex = stops?.findIndex(s => s.canceled);
  $: lastCanceled = [...(stops ?? [])].reverse()?.find?.(s => s.canceled);
  $: lastCanceledIndex = lastCanceled && stops?.indexOf(lastCanceled);
</script>

<style lang="scss">
  @import 'src/styles/variables';

  .highlight {
    font-weight: $font-weight-bold;
  }

  .stop-time {
    display: inline-block;
    width: 48px;
  }

  .stop-time-rt {
    color: $medium-emphasis;
  }

  .canceled:not(.first-arrival-canceled):not(.last-departure-canceled) {
    text-decoration: line-through;
  }

  .stop-canceled {
    text-decoration: line-through;
  }

  .stop-deleted {
    text-decoration: line-through;
    font-style: italic;
  }
</style>

<Table rows={stops} let:row let:index emptyText={$_('table.empty.default')} loadingText={$_('table.loading.default')}>
  <TableCol {row} header={$_('stop.column.sequence.label')}>
    {#if index !== undefined}
      <span class:highlight={row?.station.id === highlightedStationId}>{index + 1}</span>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('stop.column.time.arrival.label')}>
    {@const isNextDay = row?.arrivalTimeNextDay}
    {#if TripDetailsTypeEnum.Planned === tripType && row?.plannedArrivalTime?.time}
      <span class:highlight={row?.station.id === highlightedStationId}>
        {prependNextDayPrefix(row?.plannedArrivalTime?.time, isNextDay)}
      </span>
    {:else if TripDetailsTypeEnum.Operational === tripType && row?.arrivalTime}
      <span
        class="stop-time"
        class:canceled={row.canceled}
        class:deleted={row.deleted}
        class:first-arrival-canceled={index === firstCanceledIndex}
        class:highlight={row?.station.id === highlightedStationId}>
        {prependNextDayPrefix(formatTime(row.arrivalTime), isNextDay)}
      </span>
      {#if row.arrivalTimeRt}
        <span
          class="stop-time-rt"
          class:canceled={row.canceled}
          class:first-arrival-canceled={index === firstCanceledIndex}
          class:highlight={row?.station.id === highlightedStationId}>
          {prependNextDayPrefix(formatTime(row.arrivalTimeRt), isNextDay)}
        </span>
      {/if}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('stop.column.time.departure.label')}>
    {@const isNextDay = row?.departureTimeNextDay}
    {#if TripDetailsTypeEnum.Planned === tripType && row?.plannedDepartureTime?.time}
      <span class:highlight={row.station.id === highlightedStationId}>
        {prependNextDayPrefix(row.plannedDepartureTime.time, isNextDay)}
      </span>
    {:else if TripDetailsTypeEnum.Operational === tripType && row?.departureTime}
      <span
        class="stop-time"
        class:last-departure-canceled={isCancelledFromEnd && index === lastCanceledIndex}
        class:canceled={row.canceled}
        class:highlight={row.station.id === highlightedStationId}>
        {prependNextDayPrefix(formatTime(row.departureTime), isNextDay)}
      </span>
      {#if row?.departureTimeRt}
        <span
          class="stop-time-rt"
          class:canceled={row.canceled}
          class:last-departure-canceled={index === lastCanceledIndex}
          class:highlight={row.station.id === highlightedStationId}>
          {prependNextDayPrefix(formatTime(row.departureTimeRt), isNextDay)}
        </span>
      {/if}
    {/if}
  </TableCol>

  <TableCol {row} header={$_('stop.column.station.label')}>
    {#if row?.station.name}
      <span
        class:canceled={row.canceled}
        class:first-arrival-canceled={isCancelledFromEnd && index === firstCanceledIndex}
        class:highlight={row.station.id === highlightedStationId}>
        {row.station.name}
      </span>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('stop.column.platform.accessibility')}>
    {#if row?.accessibility}
      <AccessibilityIcon style="display: block" height="1.2rem" name={row.accessibility.toLowerCase()} />
    {/if}
  </TableCol>

  <TableCol {row} header={$_('stop.column.platform.arrival.label')}>
    {#if row?.arrivalPlatform}
      <span
        class:canceled={row.canceled}
        class:first-arrival-canceled={index === firstCanceledIndex}
        class:highlight={row.station.id === highlightedStationId}>
        {formatPlatform(row.arrivalPlatform)}
      </span>
    {/if}
  </TableCol>

  <TableCol {row} header={$_('stop.column.platform.departure.label')}>
    {#if row?.departurePlatform}
      <span
        class:canceled={row.canceled}
        class:last-departure-canceled={isCancelledFromEnd && index === lastCanceledIndex}
        class:highlight={row.station.id === highlightedStationId}>
        {formatPlatform(row.departurePlatform)}
      </span>
    {/if}
  </TableCol>
</Table>
